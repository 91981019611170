<template>

  <div class="page" style="max-width: 600px; margin: 0 auto;">
    <div style="text-align:center;padding:6px 0;background-color: #1283FF;">
      <el-date-picker v-model="ckdMonth" value-format="yyyy-MM" type="month" placeholder="选择月" @change="reloadData">
      </el-date-picker>
    </div>

    <div style="height:4px"></div>

    <div>
      <table class="dtable">
        <tr style="background: #1283FF; color: #fff;">
          <td colspan="5">中博集团{{ ckdMonth }} 月份成本（单位：元）</td>
        </tr>
        <tr>
          <td>机构</td>
          <td>姓名</td>
          <td>费用</td>
          <td>人力成本</td>
          <td>其他成本</td>
        </tr>
        <template v-for="(dept, idx) in deptShowList">

          <tr v-for="(cb, i) in dept.cbList" :key="i + '' + idx" :style="{ background: dept.bg }">
            <td :rowspan="dept.ywyList.length" v-if="i == 0">{{ dept.name }}</td>
            <td>{{ cb.username }}</td>
            <td>
              <input type="number" v-model="cb.fee1" style="width:50px" />
            </td>
            <td>
              <input type="number" v-model="cb.fee2" style="width:50px" />
            </td>
            <td>
              <input type="number" v-model="cb.fee3" style="width:50px" />
            </td>
          </tr>

          <tr :style="{ background: dept.bg }" v-if="dept.cbinfo">
            <td colspan="2">{{ dept.name }}成本</td>

            <td>
              <input type="number" v-model="dept.cbinfo.fee1" style="width:50px" />
            </td>
            <td>
              <input type="number" v-model="dept.cbinfo.fee2" style="width:50px" />
            </td>
            <td>
              <input type="number" v-model="dept.cbinfo.fee3" style="width:50px" />
            </td>
          </tr>

        </template>


      </table>
    </div>

    <div class="btnsubmit" @click="saveFee">保存</div>
  </div>
</template>

<script>
let fixedDept = require("./cbDept.js")

export default {
  data() {

    return {
      ckdMonth: "",
      deptList: fixedDept.deptList,
      taped: false,
      deptShowList:[]

    }
   
  },
  mounted() {
    document.title = "成本管理"
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    this.ckdMonth = year + "-" + month
    this.getUserlist()
  },
  methods: {
    reloadData() {
      this.getUserlist()
    },
    getUserlist() {
      //注意业务员名字带括号的中文括号
      let usernames = [];
      let deptList =  JSON.parse(JSON.stringify(this.deptList))
      for (let dept of deptList) {
       
          dept.ywyList = dept.ywyList.split(',')
          dept.ywyStr = dept.ywyList.map(e => { return "'" + e + "'" })
          usernames = usernames.concat(dept.ywyStr)
          dept.usernames = usernames
        

      }

      usernames = usernames.join(",")
      this.$http.post("/api/get_teachers_byname", {
        usernames: usernames,

      }).then(res => {
        for (let dept of deptList) {
         
          dept.cbinfo = {
            fee1: "",
            fee2: "",
            fee3: "",
            fee4: ""
          }
          dept.cbList = []
          for (let u of dept.ywyList) {
            for (let item of res.data) {
              if (u == item.username) {
                item.fee1 = ""
                item.fee2 = ""
                item.fee3 = ""
                item.fee4 = ""

                dept.cbList.push(item)
                break
              }
            }
          }
        }


        this.$http.post("/api/chengben_list", {
          year: this.ckdMonth.split("-")[0],
          month: parseInt(this.ckdMonth.split("-")[1]),
        }).then(res2 => {
          for (let dept of deptList) {
            console.log("target_id", dept.target_id)
            for (let item of res2.data) {
              // console.log(dept.target_id , item.target_id , item.dtype)
              if (dept.target_id == item.target_id && item.dtype == 2) {//机构
                console.log(item)
                dept.cbinfo.fee1 = item.fee1 ? item.fee1 : ''
                dept.cbinfo.fee2 = item.fee2 ? item.fee2 : ''
                dept.cbinfo.fee3 = item.fee3 ? item.fee3 : ''
                dept.cbinfo.fee4 = item.fee4 ? item.fee4 : ''
                dept.cbinfo.cbid = item.id
                dept.cbid = item.id

                break
              }
            }



            for (let u of dept.cbList) {
              for (let item of res2.data) {

                if (u.id == item.target_id && item.dtype == 1) {//业务员
                  u.fee1 = item.fee1 ? item.fee1 : ''
                  u.fee2 = item.fee2 ? item.fee2 : ''
                  u.fee3 = item.fee3 ? item.fee3 : ''
                  u.fee4 = item.fee4 ? item.fee4 : ''
                  u.cbid = item.id

                  break
                }
              }
            }
          }
          this.deptShowList = deptList



        })
      })
    },
    saveFee() {
      if (this.taped) {
        return
      }
      this.taped = true
      let arr = [];
      let deptList = JSON.parse(JSON.stringify(this.deptShowList))
      for (let dept of deptList) {
        dept.cbinfo.dtype = 2//机构
        dept.cbinfo.target_id = dept.target_id
        dept.cbinfo.id = dept.cbid
        dept.cbinfo.dyear = this.ckdMonth.split("-")[0];
        dept.cbinfo.dmonth = parseInt(this.ckdMonth.split("-")[1]);
        arr.push(dept.cbinfo)


        for (let fee of dept.cbList) {
          if (fee.cbid) {
            fee.target_id = fee.id
            fee.id = fee.cbid
          } else {
            fee.target_id = fee.id
            fee.id = 0
          }
          fee.dyear = this.ckdMonth.split("-")[0];
          fee.dmonth = parseInt(this.ckdMonth.split("-")[1]);
          fee.dtype = 1
          arr.push(fee)
        }
      }
      arr = JSON.parse(JSON.stringify(arr))
      let saveForm = idx => {
        if (idx < arr.length) {
          let u = arr[idx];
          this.$http.post("/api/chengben_edit", u).then(res => {
            saveForm(idx + 1)
          })
        } else {
          this.$message.success("保存成功")
          this.taped = false

          this.reloadData()
        }
      }
      saveForm(0)

    }

  }
}
</script>

<style scoped>
.btnsubmit {
  background-color: #3bb0f2;
  color: #fff;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-top: 20px;
}


.dtable,
.dtable tr th,
.dtable tr td {
  border: 1px solid #ccc;
}

.dtable {
  width: 100%;
  min-height: 25px;
  line-height: 25px;
  text-align: center;
  border-collapse: collapse;
  padding: 2px;
}
</style>
