let deptList = [{
    target_id: 1,
    name: "红叶代理",
    ywyList: '杨晓慧,韩承睿,朱琼玉,韩飞,张静(睢宁)',
    cbList: [],
    bg: "rgba(114,224,255,0.1)"
  }, {
    target_id: 2,
    name: "服务外包",
    ywyList: '祝梦含,张静(南京)',
    cbList: [],
    bg: "rgba(252,186,95,0.1)"
  }, {
    target_id: 3,
    name: "红叶汽销",
    ywyList: '沈祥',
    cbList: [],
    bg: "rgba(185,135,255,0.1)"
  }, {
    target_id: 4,
    name: "云慧公司",
    ywyList: '唐仲达',
    cbList: [],
    bg: "rgba(109,221,8,0.1)"
  }, {
    target_id: 5,
    name: "鼓信公司",
    ywyList: '吴楠',
    cbList: [],
    bg: "rgba(245,225,60,0.1)"
  }, {
    target_id: 6,
    name: "汉博公司",
    ywyList: '赵明宇',
    cbList: [],
    bg: "rgba(255,100,160,0.1)"
  }];
  module.exports={
    deptList
  }